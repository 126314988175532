<template>
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
        <path
            d="M32.05 44.11h18.72c.55 0 1-.45 1-1s-.45-1-1-1H32.05c-.55 0-1 .45-1 1s.45 1 1 1ZM64.21 43.11c0-.55-.45-1-1-1h-6.88c-.55 0-1 .45-1 1s.45 1 1 1h6.88c.55 0 1-.45 1-1ZM60.4 52.79c0-.55-.45-1-1-1H32.04c-.55 0-1 .45-1 1s.45 1 1 1H59.4c.55 0 1-.45 1-1ZM32.05 63.47h14.73c.55 0 1-.45 1-1s-.45-1-1-1H32.05c-.55 0-1 .45-1 1s.45 1 1 1ZM32.05 73.16h18.72c.55 0 1-.45 1-1s-.45-1-1-1H32.05c-.55 0-1 .45-1 1s.45 1 1 1Z"
            class="cls-1" />
        <path
            d="m92.24 26.22-1.22-.82a4.466 4.466 0 0 0-6.14 1.16l-1.62 2.34-22.23 33.05-1.87 2.66c-.09.12-.14.27-.17.42l-1.59 9.83c-.06.4.16.82.5 1.03.16.1.35.15.53.15s.36-.05.52-.15l8.46-5.21c.12-.07.22-.17.29-.28l.61-.87c.07-.06.14-.13.19-.21L78.85 54v31.3H21.51V14.7h57.35v13.14c0 .55.45 1 1 1s1-.45 1-1V13.7c0-.55-.45-1-1-1H20.51c-.55 0-1 .45-1 1v72.61c0 .55.45 1 1 1h59.35c.55 0 1-.45 1-1V51.05l12.58-18.63c.67-.99.91-2.17.68-3.34a4.41 4.41 0 0 0-1.89-2.84ZM59.72 73.06l1.21-7.51.06-.09L66.25 69l-.05.08-6.48 3.99Zm32.07-41.78-23.84 35.3-.55.78-5.24-3.53 22.75-33.82 1.61-2.33c.76-1.1 2.28-1.38 3.38-.64l1.22.82c.54.37.91.92 1.04 1.57.13.64 0 1.3-.37 1.84Z"
            class="cls-1" />
        <path d="M69.43 33.42c0-.55-.45-1-1-1H32.04c-.55 0-1 .45-1 1s.45 1 1 1h36.39c.55 0 1-.45 1-1Z" class="cls-1" />
    </svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
